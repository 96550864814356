import React from 'react';
import styled from 'styled-components'

const Content = (props) => {

  return (
  <section className={props.className}>
    <div className="container">
      <main dangerouslySetInnerHTML={{__html: props.content}}></main>
    </div>
  </section>
  )

}

export default styled(Content)`
  padding: 50px 0;
`;