import React from "react";
import { graphql, Link } from "gatsby"
import styled from 'styled-components';
import Img from 'gatsby-image';
import { above } from '../styles/utilities';
import Layout from '../layouts/default';
import SEO from '../components/seo';
import Div from '../components/Div';
import Hero from '../components/Hero';
import Content from '../components/Content';
import Trust from '../components/Trust';
import Location from '../components/Location';
import Footer from '../components/Footer';

const MoreInformationTemplate = (props) => {

  return (
  <Layout className={props.className} menu={props.data.menu} meta={props.data.site.siteMetadata}>

    <SEO
      title={
        props.data.content.frontmatter.seo
        && props.data.content.frontmatter.seo.title
          ? props.data.content.frontmatter.seo.title
          : props.data.site.siteMetadata.title
      }
      description={
        props.data.content.frontmatter.seo
        && props.data.content.frontmatter.seo.description
          ? props.data.content.frontmatter.seo.description
          : props.data.site.siteMetadata.description
      }
    />

    <Hero
      className="hero--default"
      menu={props.data.menu}
      hero={props.data.content.frontmatter.hero}
      phoneNumber={props.data.site.siteMetadata.phoneNumber}
    />
    <Div className="container">
      <div className="row">
        <div className="col-lg-8 p-l-0">
          <Content className="content--main" content={props.data.content.html}></Content>
        </div>
      </div>
    </Div>
    <Div className="container">
      <div className="row">
        <div className="col-lg-8 p-l-0">
          <div className="more-information__links">
            {
              props.data.links.edges.map(({node}, idx) => (
                <div className="more-information__link" key={idx}>
                  <div 
                    className="more-information__link-item more-information__link-item--image"
                  >
                    <Img fixed={node.frontmatter.hero.image.childImageSharp.fixed} />
                  </div>
                  <div className="more-information__link-item more-information__link-item--content">
                    <div dangerouslySetInnerHTML={{__html: node.excerpt}} />
                    <div><Link to={`/${node.frontmatter.slug}`}>Read More</Link></div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Div>
    <Trust images={props.data.trust} />
    <Location location={props.data.location}></Location>
    <Footer menu={props.data.footerMenu} phoneNumber={props.data.site.siteMetadata.phoneNumber}></Footer>
  </Layout>
  )

}

export default styled(MoreInformationTemplate)`
  .content--main {
    padding: 50px 0;
  }
  .p-l-0 {
    ${above.md`
      padding-left: 0;
    `}
  }

  .more-information__link {
    display: flex;
    margin-bottom: 15px;
    h1 {
      font-size: 1.25rem;
    }
    font-size: 1rem;
  }

  .more-information__link-item {
    margin-right: 15px;
  }

  .more-information__link-item--image {
    width: 300px;
  }
`;

export const query = graphql`
  query($id: String!) {

    site {
      siteMetadata {
        phoneNumber
        title
      }
    }
    
    content: markdownRemark(id: {eq: $id}) {
      frontmatter {
        hero {
          image {
            childImageSharp {
              fluid(quality:100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          image_alt
          heading
          text
          button_link
          button_text
          link_type
        }
        seo {
          title
          description
        }
      }
      html
    }

    links: allMarkdownRemark(
      filter: {
        frontmatter: {
          include_on_page: {
            in: ["more-information"]
          }
        }
      }) {
      edges {
        node {
          excerpt(format: HTML)
          frontmatter {
            slug
            hero {
              image {
                childImageSharp {
                  fixed(width:300, height:200) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
            image_alt
          }
        }
      }
    }

    location: markdownRemark(
      frontmatter: {
        name: {
          eq: "location"
        }
      }) {
      html
      frontmatter {
        button_link
        button_text
        button_type
        map
      }
    }

    menu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
        name: {
          eq: "main-menu"
        }
      }) {
        html
        frontmatter {
          menu
        }
    }

    footerMenu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
        name: {
          eq: "footer-menu"
        }
      }) {
        html
        frontmatter {
          menu
        }
    }

    trust: allFile(filter: {
      name: {
        in: [
          "american-optometric-association-logo",
          "florida-optometric-association",
          "lenscrafters-logo"
        ]
      }
    }) {
      edges {
        node {
          childImageSharp {
            fixed(quality: 100, width: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

  }
`;